<template>
    <div class="div">
        <div class="top" @click="throttleSubmit">
            <div class="img"><img src="https://oss.hshwhkj.com/images/Tiktok/dyC/dyCbg.jpg" alt="" /></div>
            <div class="text">领取易经大师全套资料</div>
            <div class="btn">加微信</div>
        </div>
        <div class="body">
            <img src="https://oss.hshwhkj.com/images/Tiktok/dyC/dyC001.webp" alt="" />
                    <img src="https://oss.hshwhkj.com/images/Tiktok/dyC/dyC002.webp" alt="" />
            <img src="https://oss.hshwhkj.com/images/Tiktok/dyC/dyC003.webp" alt="" />
            <img src="https://oss.hshwhkj.com/images/Tiktok/dyC/dyC004.webp" alt="" />
            <img src="https://oss.hshwhkj.com/images/Tiktok/dyC/dyC005.webp" alt="" />

            <img src="https://oss.hshwhkj.com/images/3.gif" alt="" class="wx" @click="throttleSubmit" />
                <div class="bottomText" v-if="tg_id==2">苏州安捷成文化科技有限公司 </div>
       <div class="bottomText" v-else>上海河山汇文化科技有限公司 </div>
        </div>
        <div class="bto" @click="throttleSubmit">
            <div class="img"><img src="https://oss.hshwhkj.com/images/Tiktok/dyC/dyCbg.jpg" alt="" /></div>
            <div class="text">快来咨询吧</div>
            <div class="btn">加微信</div>
        </div>
                <el-dialog :visible.sync="dialogFormVisible" width="82%" :lock-scroll="true" :close-on-click-modal='false'>
            <div v-if="flages">
                <div style="" class="textTitle">
                    1.请问您对《易经》的了解程度如何? <span style="color: red">*</span>
                </div>
                <div style="margin-top: 20px">
                    <div
                        v-for="(text, index) in texts"
                        :key="index"
                        @click="handleClick(index)"
                        :class="{active: index === selected}"
                        class="texts"
                    >
                        {{ text }}
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="textTitle">2.请问您希望学习《易经》中的哪些知识? <span style="color: red">*</span></div>
                <div style="margin-top: 20px">
                    <div  
                        v-for="(text, index) in textsA"
                        :key="index"
                        @click="throttleSubmitA(index)"
                        :class="{active: index === selectedA}"
                        class="texts"
                    >
                        {{ text }}
                    </div>
                </div>
            </div>
        </el-dialog>

        <!-- 确认弹框 -->
        <el-dialog
            :visible.sync="suessesDir"
            width="82%"
            :lock-scroll="true"
            :show-close="false"
            :close-on-click-modal="false"
            :destroy-on-close="true"
        >
            <!-- <div v-if="dirFlag">
              <div style="margin-top: -40px;margin-bottom: 20px;" class="phonesCss">输入手机号，领取福利</div>
                <el-form  >
                    <el-form-item >
                        <el-input placeholder="信息已加密，请放心填写" v-model="phones" ></el-input>
                    </el-form-item>
                    <el-button style="width: 100%" @click="phoneFlag" round class="submitbtns" 
                        :class="{activeSubmit: phones.length === 11 }"
                    
                    >提交</el-button>
                </el-form>
            </div> -->

            <div v-if="!dirFlag" class="dirsA">
                <div class="imgsA">
                    <img src="https://hshcrm.oss-cn-hangzhou.aliyuncs.com/images/succeed.png" alt="" />
                </div>
                <!-- <div class="audio">
                    <audio controls autoplay ref="myAudio" @ended="audioEnded" @loadedmetadata="getAudioDuration">
                        <source
                            src="https://hshcrm.oss-cn-hangzhou.aliyuncs.com/images/Tiktok/%E5%9B%B0%E5%A2%83.mp3"
                            type="audio/mp3"
                        />
                        Your browser does not support the audio tag.
                    </audio>
                </div> -->
                <div class="imgsB" @click="throttleSubmitB">
                    <div  >
                        <el-button class="btnns" style="background-color: #c83732; color: #fff"
                            >添加老师,免费领取直播课></el-button
                        >
                        <div style="width: 100% ;" class="tips">注:70岁以上请勿添加</div>
                    </div>
                    <!-- <div v-else>
                        <el-button class="btnns">
                            <div>
                                <span style="color: #c83732"> {{ this.audioTime }}</span
                                >秒后,领取福利
                            </div>
                        <div style="width: 100%;margin: 10px 0;" class="tips">注:70岁以上请勿添加</div>

                        </el-button>
                    </div> -->
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
//引入
import {getWxCsad} from "../../api/360AD.js";

export default {
    name: "tiktokAD",
    components: {},
    data() {
        return {
                 audioTime: 0,
            audioSrc: "",
            DirFlag: false,
            audioFlag: false,
                 phones:'',
          dirFlag:false,
             flages: true,
            dialogFormVisible: false,
            suessesDir: false,
            texts: ["略有一些了解", "有一些自己见解", "不是很了解"],
            textsA: ["面相识人学", "旺运姓名学", "家居风水学", "八字命理学"],
            selected: null,
            selectedA: null,
      tg_id:'',

            ruleForm: {
                land_link: "", //落地页链接
            },
        };
    },
    created() {
        this.ruleForm.land_link = window.location.href;
   this.tg_id=this.$route.query.tg_id

    },
    methods: {
        getAudioDuration() {
            const audioElement = this.$refs.myAudio;
            console.log(audioElement.duration);
            this.audioTime = Math.trunc(audioElement.duration);
            let countdownTimer = setInterval(() => {
                // 检查时间是否已经减到0
                if (this.audioTime > 0) {
                    // 时间减一
                    this.audioTime--;
                } else {
                    // 当时间到达0时，清除定时器
                    clearInterval(countdownTimer);
                    // 可以在此处添加计时结束后的处理逻辑
                    console.log("Countdown finished!");
                }
            }, 1000);
        },
        audioEnded() {
            console.log("音频播放完毕");
            // 在这里添加您希望在音频播放结束时执行的操作
            this.audioFlag = true;
        },
        phoneFlag() {
            const phoneRegex = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
            if (this.phones.length >= 11 && phoneRegex.test(this.phones)) {
                this.dirFlag = false;
            } else {
                this.$message({type: "error", message: "请输入正确的手机号"});
            }
        },
    handleClick(index) {
            this.selected = index;
            this.flages = false;
        },
        // handleClickA(index) {
        //     this.selectedA = index;
        //     this.wxClick();
        // },
        throttleSubmit() {
            const currentTime = Date.now();
            if (currentTime - this.lastClickTime < 2500) {
                return; // 在一秒内多次点击，直接返回
            }
            this.lastClickTime = currentTime; // 更新上次点击时间
            // this.openDir(); // 调用实际的支付方法
            //   this.$router.push(
            //   {
            //     path: "/wenda",
            //     query: {
            //       tg_id: this.tg_id,
            // land_link: this.ruleForm.land_link,
            //     }
            //   }
            // )
            this.wxClick()
        },
        throttleSubmitA(index) {
            const currentTime = Date.now();
            if (currentTime - this.lastClickTime < 600) {
                return; // 在一秒内多次点击，直接返回
            }
            this.lastClickTime = currentTime; // 更新上次点击时间

            this.selectedA = index;
            this.dialogFormVisible = false;
            this.suessesDir = true;
            this.flages = true;
       
            // this.wxClick(); // 调用实际的支付方法
        },
                throttleSubmitB() {
            const currentTime = Date.now();
            if (currentTime - this.lastClickTime < 600) {
                return; // 在一秒内多次点击，直接返回
            }
      
            this.lastClickTime = currentTime; // 更新上次点击时间
            this.wxClick(); // 调用实际的支付方法
        },
        // 打开弹框
        openDir() {
            this.dialogFormVisible = true;
        },
        closeDir() {
            this.dialogFormVisible = false;
            this.flages = true;
            this.selected = null;
            this.selectedA = null;
        },
     async wxClick() {
            // console.log(this.selected);
            // console.log(this.selectedA);
            // if (this.selected === null || this.selectedA === null) {
            //     this.$message({type: "error", message: "请选择选项"});
            // } else {
                const {data} = await getWxCsad(this.ruleForm);
                // console.log(data);
                window.location.href = data.data;
                // this.dialogFormVisible = false;
            // }
        },
    },
};
</script>

<style scoped lang="less">
.div {
    width: 100%;
    overflow: hidden;
    .phonesCss{
  font-weight: 700;
  font-size: 18px;
  color: #C83732;
}
.submitbtns{
  color: #adadad;
  background-color: #e8e8e8;
}
.activeSubmit{
   color: #fff;
  background-color: #c83732;
}
    .texts {
        background-color: #f8f9fb;
        height: 42px;
        width: 263px;
        line-height: 42px;
        text-align: center;
        margin-top: 20px;
        font-size: 16px;
        border-radius: 12px;
    }
    .texts.active {
        font-size: 16px;
        /* 被点击时的样式 */
        background-color: #e7eeff;
        border: #b9cdfe 1px solid;
        border-radius: 12px;
    }
    .textTitle {
        font-size: 16px;
        margin: 0 auto;
        font-weight: 700;
    }
     .el-dialog{
        overflow: hidden;
    }
    .dirsA{
    }
    .imgsA {
        width: 120px;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 10px;

        img {
            width: 100%;
            height: 100%;

            object-fit: contain;
        }
    }
    .imgsB {
        width: 250px;
        height: 58px;
        margin: 0 auto;
        img {
            width: 100%;
            height: 58px;
            object-fit: contain;
        }
        .tips{
            height: 30px;
            font-weight: 700;
            text-align: center;
            font-size: 16px;
            margin-bottom: 10px;
        }
                .btnns {
        background-color: #e8e8e8;
        border: none;
        color: #adadad;
        width: 250px;
        height: 25px;
        border-radius: 50px;
         animation-name: btnns;
    animation-duration: 1s;  
    animation-iteration-count: infinite; 
    animation-direction: alternate;  
    font-size: 18px;
    }
    }
    .dirClass{
      border-radius: 12px;
    }
  
        .audio {
        audio {
            width: 250px;

            height: 45px;
            margin: 10px auto;
        }
    }
    .top {
        display: flex;
        width: 100%;
        height: 60px;
        line-height: 60px;
        align-items: center;
        background-color: #fff;
        position: fixed;
        top: 0;
        .img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            margin: 0 10px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .text {
            color: rgb(0, 0, 0);
            font-size: 20px;
        }
        .btn {
            margin-left: 20px;
            width: 70px;
            height: 40px;
            background-color: #af7a03;
            text-align: center;
            line-height: 40px;
            border-radius: 20px;
            font-size: 18px;
            color: #fff;

            animation: btn 0.8s infinite;
        }
    }

    .bto {
        display: flex;
        width: 100%;
        height: 60px;
        line-height: 60px;
        align-items: center;
        background-color: #fff;
        position: fixed;
        justify-content: space-between;
        bottom: 0;
        .img {
            width: 40px;
            height: 40px;
            border-radius: 50%;

            overflow: hidden;
            margin: 0 10px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .text {
            color: rgb(0, 0, 0);
                font-size: 20px;
flex: 1;
        }
        .btn {
            margin-left: 20px;
            margin-right: 10px;
            width: 70px;

            height: 40px;
            background-color: #af7a03;
            text-align: center;
            line-height: 40px;
            border-radius: 20px;
            font-size: 18px;

            color: #fff;
            animation: btn 0.8s infinite;
        }
    }
    .body {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 60px;
        margin-bottom: 60px;
        img {
            width: 100%;
            object-fit: contain;
        }
        .wx {
            position: fixed;
            top: 50%;
            right: 0;
            width: 90px;
            height: 90px;
            object-fit: contain;
            cursor: pointer;
        }
        .bottomText{
          width: 100%;
          text-align: center;
      font-family: SimSun, "宋体", STSong, "华文宋体", serif;
         color:  rgba(115, 114, 110, 1);
font-size: 24px;
}
    }
    // @keyframes btn {
    //     0% {
    //         transform: scale(1);
    //     }
    //     50% {
    //         transform: scale(1.1);
    //     }
    //     100% {
    //         transform: scale(1);
    //     }
    // }
}
@media screen and (min-width: 750px) {
    .div {
        width: 550px;
        overflow: hidden;
        margin: 0 auto;
        .phonesCss{
  font-weight: 700;
  font-size: 18px;
  color: #C83732;
}
.submitbtns{
  color: #adadad;
  background-color: #e8e8e8;
}
.activeSubmit{
   color: #fff;
  background-color: #c83732;
}
    .texts {
        background-color: #f8f9fb;
        height: 42px;
        width: 263px;
        line-height: 42px;
        text-align: center;
        margin-top: 20px;
        font-size: 16px;
        border-radius: 12px;
    }
    .texts.active {
        font-size: 16px;
        /* 被点击时的样式 */
        background-color: #e7eeff;
        border: #b9cdfe 1px solid;
        border-radius: 12px;
    }
    .textTitle {
        font-size: 16px;
        margin: 0 auto;
        font-weight: 700;
    }
  .imgsA {
        width: 120px;
        margin: 0 auto;
        margin-bottom: 20px;

        img {
            width: 100%;
            height: 100%;

            object-fit: contain;
        }
    }
  
    .dirClass{
      border-radius: 12px;
    }
    
        .audio {
        audio {
            width: 250px;

            height: 45px;
            margin: 10px auto;
        }
    }
        .top {
            display: flex;
            width: 550px;
            height: 60px;
            line-height: 60px;
            align-items: center;
            justify-content: space-between;

            background-color: #fff;
            position: fixed;
            top: 0;
            cursor: pointer;
            .img {
                width: 40px;
                height: 40px;
                overflow: hidden;
                margin: 0 10px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .text {
                flex: 1;
                color: rgb(0, 0, 0);

                font-size: 24px;
            }
            .btn {
                width: 80px;
                height: 40px;
                 background-color: #af7a03;

                text-align: center;
                line-height: 40px;
                border-radius: 20px;
                font-size: 22px;
                color: #fff;
                animation: btn 0.8s infinite;
            }
        }

        .bto {
            display: flex;
            width: 550px;
            height: 60px;
            line-height: 60px;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            position: fixed;
            cursor: pointer;

            bottom: 0;

            .img {
                width: 40px;
                height: 40px;
                overflow: hidden;
                margin: 0 10px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .text {
                color: rgb(0, 0, 0);

                font-size: 24px;
            }
            .btn {
                margin-left: 20px;
                width: 80px;
                height: 40px;
                   background-color: #af7a03;

                text-align: center;
                line-height: 40px;
                border-radius: 20px;
                font-size: 22px;
                color: #fff;
                animation: btn 0.8s infinite;
            }
        }
        .body {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 60px;
            margin-bottom: 60px;
            img {
                width: 100%;
                object-fit: contain;
            }
            .wx {
                position: fixed;
                top: 50%;
                right: 28%;
                width: 90px;
                height: 90px;
                object-fit: contain;
                cursor: pointer;
            }
              .bottomText{
          width: 100%;
          text-align: center;
      font-family: SimSun, "宋体", STSong, "华文宋体", serif;
         color:  rgba(115, 114, 110, 1);
font-size: 24px;
}
        }
       
                .textTitle {
            margin: 0 auto;
            width: 200px;
        }
        .textTitle1 {
            margin: 0 auto;
            width: 200px;
            font-weight: 700;
        }
        .textTitle {
            font-size: 16px;
            margin: 0 auto;
            font-weight: 700;
        }
        .texts {
            margin: 0 auto;
            background-color: #f8f9fb;
            height: 42px;
            width: 263px;
            line-height: 42px;
            text-align: center;
            margin-top: 20px;
            border-radius: 12px;
            font-size: 16px;
        }
        .texts.active {
            /* 被点击时的样式 */
            background-color: #e7eeff;
            border: #b9cdfe 1px solid;
            border-radius: 12px;
            font-size: 16px;

        }
        
    }

}
@keyframes btnns {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
</style>
